$(document).ready(function () {
	$('body').on('hidden.bs.modal', '.modal', function () {
		$(this).removeData('bs.modal');
	});
	

	if ($('[data-fancybox="true"]').length > 0) {
		try {
			$('[data-fancybox="true"]').fancybox({
				padding: 0,
				margin: 0,
				loop: true,
				arrows: true,
				type: 'image',
				autoSize: false,
				autoResize: true,
				autoCenter: true,
				fitToView: true,
				aspectRatio: true,
				openSpeed: 0,
				closeSpeed: 0,
				nextSpeed: 0,
				prevSpeed: 0,
				preload: 1,
				nextClick: true,
				closeBtn: true,
				maxWidth: $(window).width() * 0.8,
				maxHeight: $(window).height() * 0.8,
				title: $(this).attr('title')
			});
		} catch (ex) {
			console.log(ex);
		}
	}


	if ($('[data-masonry="true"]').length > 0) {
		try {
			var wrapper = $('[data-masonry="true"]').masonry({
				itemSelector: '[data-masonry-item="true"]'
			});

			$('[data-masonry="true"]').find('img').on('load', function () {
				wrapper.masonry();
			});
		} catch (ex) {
			console.log(ex);
		}
	}
	

	$(document).on('submit', 'form[data-remote="true"]', function (e) {
		var form = $(this);

		form.find('[type="submit"]')
			.html('<i class="icon icon-spinner icon-spin"></i> Идёт отправка')
			.attr('disabled', 'disabled');

		jQuery.ajax({
			url: form.attr('action'),
			type: form.attr('method'),
			data: form.serialize(),
			dataType: 'html',
			success: function (result) {
				$(form.parents('.modal-content')[0]).html(result);
			}
		});

		e.preventDefault();
	});


	$("a[href$='.jpg'], a[href$='.png'], a[href$='.gif']").fancybox({
		helpers: {
			title: {
				type: 'over'
			}
		},
		padding: '0',
		maxWidth: $(window).width() * 0.8,
		maxHeight: $(window).height() * 0.8,
		beforeShow: function () {
			var imgAlt = $(this.element).find("img").attr("alt");
			var dataAlt = $(this.element).data("alt");

			$(".fancybox-image").attr("alt", (imgAlt ? imgAlt : dataAlt));
		}
	});
});

$("a.scroll-to").on("click", function (e) {
	e.preventDefault();
	var anchor = $(this).attr('href');
	$('html, body').stop().animate({
		scrollTop: $(anchor).offset().top - 130
	}, 1000);
});




//BEGIN BACK TO TOP
var scrollTop = $("#totop");
$("#totop").hide();
$(window).scroll(function () {
	if ($(this).scrollTop() > 700) {
		$("#totop").show();
	} else {
		$("#totop").hide();
	}
});
$("#totop").on('click', function () {
	$("html, body").animate({ scrollTop: 0 }, 'fast');
	return false;
});
//END BACK TO TOP

if ($('[data-masonry="true"]').length > 0) {
	try {
		var wrapper = $('[data-masonry="true"]').masonry({
			itemSelector: '[data-masonry-item="true"]'
		});

		$('[data-masonry="true"]').find('img').on('load', function () {
			wrapper.masonry();
		});
	} catch (ex) {
		console.log(ex);
	}
}

//Logo Partners

(function () {
	// setup your carousels as you normally would using JS
	// or via data attributes according to the documentation
	// https://getbootstrap.com/javascript/#carousel
	$('#carousel123').carousel({ interval: 2000 });
	$('#carouselABC').carousel({ interval: 3600 });
}());

(function () {
	$('.carousel-showmanymoveone .item').each(function () {
		var itemToClone = $(this);

		for (var i = 1; i < 6; i++) {
			itemToClone = itemToClone.next();

			// wrap around if at end of item collection
			if (!itemToClone.length) {
				itemToClone = $(this).siblings(':first');
			}

			// grab item, clone, add marker class, add to collection
			itemToClone.children(':first-child').clone()
				.addClass("cloneditem-" + (i))
				.appendTo($(this));
		}
	});
}());




