$(document).ready(function () {
	$('.ajxAddCart').on('click', function () {
		pid = $(this).data('pid');

		type = $(this).data('type');

		$.post('/cart/add', 'pid=' + pid + '&type=' + type, function (response) {
			data = jQuery.parseJSON(response);

			if (data.status == 0) {
				alert(data.text);

				return false;
			}

			$('.cartAllNumHead').html(data.all);

			$('#modal-remote').modal({
				remote: '/cart/alert'
			});

			// data.text
		});
	});

	$('.ajxUpdateCart').on('keyup', function () {
		pid = $(this).data('pid');

		type = $(this).data('type');

		num = $(this).val();

		if (num == '') {
			return false;
		}

		$.post('/cart/update', 'pid=' + pid + '&type=' + type + '&num=' + num, function (response) {
			data = jQuery.parseJSON(response);

			if (data.status == 0) {
				alert(data.text);

				return false;
			}

			$('#cart-' + type + '-' + pid + ' .cartSum').html(data.text);

			$('.cartAllNum').html(data.all + ' ' + data.title);

			$('.cartAllNumHead').html(data.all);

			$('.cartAllSum').html(data.sum);
		});
	});

	$('.ajxDelCart').on('click', function () {
		pid = $(this).data('pid');

		type = $(this).data('type');

		$.post('/cart/del', 'pid=' + pid + '&type=' + type, function (response) {
			data = jQuery.parseJSON(response);

			if (data.status == 0) {
				alert(data.text);

				return false;
			}

			if (data.all == 0) {
				window.location.href = '/cart';

				return false;
			}

			$('#cart-' + type + '-' + pid).fadeOut().remove();

			$('.cartAllNum').html(data.all + ' ' + data.title);

			$('.cartAllNumHead').html(data.all);

			$('.cartAllSum').html(data.sum);
		});
	});
});
